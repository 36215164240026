'use client';

import '@bloom/library/assets/stylesheets/theme.css';
import '@bloom/library/assets/stylesheets/typefaces-remote.css';

import { useEffect } from 'react';

import Bugsnag from '@bugsnag/js';

import { UserResponse } from '@bloom/codegen/models/UserResponse';

import { PrimaryButton } from '@bloom/ui/components/PrimaryButton';

import ErrorPage from '@bloom/library/components/ErrorPage';
import { SharedQueryKeyEnum } from '@bloom/library/components/hooks/interface';
import { getQueryClient } from '@bloom/library/utils/get-query-client';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const queryClient = getQueryClient();
  const me = queryClient.getQueryData<UserResponse>([SharedQueryKeyEnum.USER_ME]);

  function handleReloadClick() {
    window.location.reload();
  }

  if (process.env.BUGSNAG_API_KEY) {
    Bugsnag.start({
      apiKey: process.env.BUGSNAG_API_KEY,
      appVersion: process.env.BLOOM_BUILD_ID,
      maxBreadcrumbs: 10,
      onError: async (event) => {
        if (event.errors[0]?.errorMessage.match(/Minified React error #\d+/)) {
          return false;
        }
        return true;
      },
      releaseStage: process.env.APP_ENV,
      user: me ? { email: me.email, id: me.id, name: `${me.firstName} ${me.lastName}` } : undefined,
    });
  }

  useEffect(() => {
    if (process.env.BUGSNAG_API_KEY) {
      Bugsnag.notify(error, (event) => {
        event.severity = 'error';
        event.unhandled = true;
      });
    }
  }, [error]);

  return (
    <html>
      <body className="min-h-screen">
        <ErrorPage className="m-auto" />
      </body>
    </html>
  );
}
